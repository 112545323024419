import { LayoutComponent } from "../layout/layout.component";

import { LoginComponent } from "./login/login.component";
import { RecuperarSenhaComponent } from "./recuperar-senha/recuperar-senha.component";
import { NovaSenhaComponent } from "./recuperar-senha/nova-senha/nova-senha.component";

export const routes = [

    {
        path: "",
        component: LayoutComponent,
        children: [
            { path: "", redirectTo: "home", pathMatch: "full" },
            { path: "home", loadChildren: "./home/home.module#HomeModule" },
            { path: "user", loadChildren: "./user/user.module#UserModule" },
            { path: "clientes", loadChildren: "../clientes/clientes.module#ClientesModule" },
            { path: "circuitos", loadChildren: "../circuitos/circuitos.module#CircuitosModule" },
            { path: "categorias", loadChildren: "../categorias/categorias.module#CategoriasModule" },
            { path: "filmes", loadChildren: "../filmes/filmes.module#FilmesModule" },
            { path: "materiais", loadChildren: "../materiais/materiais.module#MateriaisModule" },
            { path: "liberacaoMateriais", loadChildren: "../liberacaoMateriais/liberacaoMateriais.module#LiberacaoMateriaisModule" },
            { path: "relatorioDownloads", loadChildren: "../relatorioDownloads/relatorioDownloads.module#RelatorioDownloadsModule" },
            { path: "programacao", loadChildren: "../programacao/programacao.module#ProgramacaoModule" }
        ]
    },

    { path: "login", component: LoginComponent },
    { path: "recuperar-senha", component: RecuperarSenhaComponent },
    { path: "recuperar-senha/nova-senha/:tokenRecuperacao/:idUsuario/:idRecuperacao", component: NovaSenhaComponent },
    { path: "validar/nova-senha/:idUsuario/:tokenValidacao", component: NovaSenhaComponent },


    // Not found
    { path: "**", redirectTo: "home" }

];
