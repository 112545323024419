import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutesService } from 'app/routes/routes.service';
import { FiltroMultiplex } from '../../modelo/filtromultiplex';

@Component({
  selector: 'filtro-periodo',
  templateUrl: './periodo.component.html',
  styleUrls: ['./periodo.component.scss']
})
export class PeriodoComponent implements OnInit, ControlValueAccessor {

  periodo = '';
  dataInicio: string = new Date((new Date().getTime()) - (1000 * 60 * 60 * 24 * 7)).toISOString().substr(0, 10);
  dataTermino: string = new Date().toISOString().substr(0, 10);

  datasDisabled: boolean;

  changeCallback: (item: FiltroMultiplex) => void;
  touchCallback: () => void;

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId = 'periodo-filtro';

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name filtroModel
     * @type {FiltroMultiplex}
     */
  @Input() public filtro: FiltroMultiplex;

  constructor(private server: RoutesService, private router: Router) { }

  ngOnInit() {
    if(!this.server.isUsuarioLogado()){
      this.router.navigateByUrl('/login');
      
    } else{
      this.filtro.dataInicio = this.dataInicio;
      this.filtro.dataTermino = this.dataTermino;
    }
    
  }

  setPeriodo() {
    this.filtro.periodo = this.periodo;

    this.datasDisabled = this.periodo.length > 0;
  }

  setDateFrom() {
    this.filtro.dataInicio = this.dataInicio;
  }

  setDateTo() {
    this.filtro.dataTermino = this.dataTermino;
  }

  public get filtroObj(): FiltroMultiplex {
    return this.filtro;
  }

  public writeValue(item: FiltroMultiplex) {
    this.filtro = item || new FiltroMultiplex();
  }

  public registerOnChange(fn: () => void) {
    this.changeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }
}
