import './vendor.ts';
import { enableProdMode , LOCALE_ID} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

let p = platformBrowserDynamic().bootstrapModule(AppModule, {
    providers: [{provide: LOCALE_ID, useValue: 'pt-br'}]
    });
p.then(() => { (<any>window).appBootstrap && (<any>window).appBootstrap(); });
// .catch(err => console.error(err));
