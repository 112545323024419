import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BasicLoader } from './basic/basic.component';
import { PacmanLoader } from './pacman/pacman.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalModule],
  declarations: [BasicLoader, PacmanLoader],
  exports: [BasicLoader, PacmanLoader]
})
export class LoadersModule { }
