import { Component, Input, HostListener, AfterViewInit, OnDestroy, SimpleChanges, OnChanges, HostBinding, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITimepickerEvent } from './ITimepickerEvent';

import 'moment/locale/pt-br';
import * as moment from 'moment';

const CUSTOM_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Ng2DatepickerComponent),
    multi: true
};

const timepickerDefault = {
    locale: 'pt-br',
    icon: 'fa fa-clock-o',
    minuteStep: 1,
    hourStep: 1,
    showMeridian: false
};

declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'datetime',
    providers: [CUSTOM_ACCESSOR],
    templateUrl: './ng2-datepicker.component.html',
    styles: [
        '.ng2-datetime *[hidden] { display: none; }'
    ]
})

export class Ng2DatepickerComponent implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges {
    @Input('placeholder') placeholder = '';
    @Input('timepicker') timepickerOptions: any = false;
    @Input('datepicker') datepickerOptions: any = {
        locale: 'pt-br',
        icon: 'fa fa-calendar',
        format: 'dd/mm/yyyy',
        autoclose: true
    };
    @Input('hasClearButton') hasClearButton: boolean;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Input() tabindex: string;

    @Input() disabled: boolean;
    date: Date; // ngModel
    dateModel: string;
    timeModel: string;

    // instances
    datepicker: any;
    timepicker: any;

    idDatePicker: string = uniqueId('q-datepicker_');
    idTimePicker: string = uniqueId('q-timepicker_');



    constructor() {
    }

    onChange = (_: any) => {
    }

    @HostListener('blur')
    onTouched = () => {
    }

    @HostBinding('attr.tabindex')
    get tabindexAttr(): string | undefined {
        return this.tabindex === undefined ? '-1' : undefined;
    }

    ngAfterViewInit() {
        if (this.timepickerOptions === 'default') {
            this.timepickerOptions = timepickerDefault;
        }
        this.init();
    }

    ngOnDestroy() {
        if (this.datepicker) {
            this.datepicker.datepicker('destroy');
        }
        if (this.timepicker) {
            this.timepicker.timepicker('remove');
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (changes['datepickerOptions'] && this.datepicker) {
                this.datepicker.datepicker('destroy');

                if (changes['datepickerOptions'].currentValue) {
                    this.datepicker = null;
                    this.init();
                } else if (changes['datepickerOptions'].currentValue === false) {
                    this.datepicker.remove();
                }
            }
            if (changes['timepickerOptions'] && this.timepicker) {
                this.timepicker.timepicker('remove');

                if (changes['timepickerOptions'].currentValue) {
                    this.timepicker = null;
                    this.init();
                } else if (changes['timepickerOptions'].currentValue === false) {
                    this.timepicker.parent().remove();
                }
            }
        }
    }

    writeValue(value: any) {
        const d = new Date();
        const tzh = (d.getTimezoneOffset() / 60);
        const tzm = d.getTimezoneOffset() - (tzh * 60);
        const tz = (d.getTimezoneOffset() > 0 ? '-' : '+') + ( tzh < 10 ? '0' + tzh : tzh) + ( tzm < 10 ? '0' + tzm : tzm);

        if (typeof value === 'string' && value.length === 10) {
            value += 'T00:00:00.000' + tz;
        }

        this.date = typeof value === 'string' ? new Date(value) : value;
        if (isDate(this.date)) {
            setTimeout(() => {
                this.updateModel(this.date);
            }, 0);
        } else {
            this.clearModels();
        }
    }

    registerOnChange(fn: (_: any) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }

    setDisabledState(disabled) {
        this.disabled = disabled ? true : null;
    }

    checkEmptyValue(e: any) {
        const value = e.target.value;
        if (value === '' && (
            this.timepickerOptions === false ||
            this.datepickerOptions === false ||
            (this.timeModel === '' && this.dateModel === '')
        )) {
            this.onChange(undefined);
        }
    }

    clearModels() {
        this.onChange(undefined);
        if (this.timepicker) {
            this.timepicker.timepicker('setTime', null);
        }
        this.updateDatepicker(null);
    }

    showTimepicker() {
        this.timepicker.timepicker('showWidget');
    }

    showDatepicker() {
        this.datepicker.datepicker('show');
    }

    //////////////////////////////////

    private init(): void {

        moment.locale('pt-br');

        if (!this.datepicker && this.datepickerOptions !== false) {
            const options = jQuery.extend({ enableOnReadonly: !this.readonly }, this.datepickerOptions);
            this.datepicker = (<any>$('#' + this.idDatePicker)).datepicker(options);
            this.datepicker
                .on('changeDate', (e: any) => {
                    const newDate: Date = e.date;

                    if (isDate(this.date) && isDate(newDate)) {
                        // get hours/minutes
                        newDate.setHours(this.date.getHours());
                        newDate.setMinutes(this.date.getMinutes());
                        newDate.setSeconds(this.date.getSeconds());
                    }

                    this.date = newDate;

                    const tzh = (newDate.getTimezoneOffset() / 60);
                    const tzm = newDate.getTimezoneOffset() - (tzh * 60);
                    const tz = (newDate.getTimezoneOffset() > 0 ? '-' : '+')
                                        + ( tzh < 10 ? '0' + tzh : tzh)
                                        + ( tzm < 10 ? '0' + tzm : tzm);

                    const tempDate = this.timepickerOptions !== false
                                        ?
                                        (newDate.toISOString().slice(0, 19) + tz)
                                        :
                                        (newDate.toISOString().slice(0, 10) + 'T00:00:00' + tz);

                    const isoDate = new Date(tempDate);
                    this.onChange( this.timepickerOptions !== false ? isoDate.toISOString() : isoDate.toISOString().slice(0, 10));
                });
        } else if (this.datepickerOptions === false) {
            (<any>$('#' + this.idDatePicker)).remove();
        }

        if (!this.timepicker && this.timepickerOptions !== false) {
            const options = jQuery.extend({ defaultTime: false }, this.timepickerOptions);
            this.timepicker = (<any>$('#' + this.idTimePicker)).timepicker(options);
            this.timepicker
                .on('changeTime.timepicker', (e: ITimepickerEvent) => {
                    let { meridian, hours } = e.time;

                    if (meridian) {
                        // has meridian -> convert 12 to 24h
                        if (meridian === 'PM' && hours < 12) {
                            hours = hours + 12;
                        }
                        if (meridian === 'AM' && hours === 12) {
                            hours = hours - 12;
                        }
                        hours = parseInt(this.pad(hours), 10);
                    }

                    if (!isDate(this.date)) {
                        this.date = new Date();
                        this.updateDatepicker(this.date);
                    }

                    this.date.setHours(hours);
                    this.date.setMinutes(e.time.minutes);
                    this.date.setSeconds(e.time.seconds);
                    const isoDate = this.date.toISOString();
                    this.onChange(isoDate);
                });
        } else if (this.timepickerOptions === false) {
            (<any>$('#' + this.idTimePicker)).parent().remove();
        }

        this.updateModel(this.date);
    }

    private updateModel(date: Date): void {
        this.updateDatepicker(date);

        // update timepicker
        if (this.timepicker !== undefined && isDate(date)) {
            let hours = date.getHours();
            if (this.timepickerOptions.showMeridian) {
                // Convert 24 to 12 hour system
                hours = (hours === 0 || hours === 12) ? 12 : hours % 12;
            }
            const meridian = date.getHours() >= 12 ? ' PM' : ' AM';
            const time =
                this.pad(hours) + ':' +
                this.pad(this.date.getMinutes()) + ':' +
                this.pad(this.date.getSeconds()) +
                (this.timepickerOptions.showMeridian || this.timepickerOptions.showMeridian === undefined
                    ? meridian : '');
            this.timepicker.timepicker('setTime', time);
            this.timeModel = time; // fix initial empty timeModel bug
        }
    }

    private updateDatepicker(date?: any) {
        if (this.datepicker !== undefined) {
            this.datepicker.datepicker('update', date);
        }
    }

    private pad(value: any): string {
        return value.toString().length < 2 ? '0' + value : value.toString();
    }
}

let id = 0;
function uniqueId(prefix: string): string {
    return prefix + ++id;
}

function isDate(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Date]';
}
