import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TagInputModule } from "ngx-chips-angular";
import { DatePickerModule } from "./../components/datepicker/datepicker.module";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { PeriodoComponent } from "./periodo/periodo.component";
import { LocalidadeComponent } from "./localidade/localidade.component";
import { ProdutoComponent } from "./produto/produto.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, TagInputModule, DatePickerModule ],
  declarations: [PeriodoComponent, LocalidadeComponent, DashboardComponent, ProdutoComponent],
  exports: [PeriodoComponent, LocalidadeComponent, DashboardComponent, ProdutoComponent]
})
export class FiltroModule { }
