import { Injectable } from '@angular/core';
import { CookieService as NgCookie } from 'ngx-cookie-service';
import * as Global from '../global';

@Injectable()
export class CookieService {

  constructor(private ngCookieServer: NgCookie){
  }

  public setCookie (name,value,expires?,path?,theDomain?,secure?) :void {
    this.ngCookieServer.set(name,value,expires,path,Global.COOKIE_DOMAIN_PATH,secure);
  }
  public getCookie(cname): string {
    return this.ngCookieServer.get(cname);
  }



 /* public setCookie(cname: string, cvalue: string, expminutes: number): void {
        let expires = '';
        if (expminutes) {
            const d = new Date();
            d.setTime(d.getTime() + (expminutes * 60 * 1000));
            expires = 'expires=' + d.toUTCString();
        }
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/;domain=' + Global.COOKIE_DOMAIN_PATH;
    }*/
/*
    public getCookie(cname): string {

        console.debug('attemp to get cookie: ' + cname);

        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {

                console.debug('found cookie: ' + cname);

                return c.substring(name.length, c.length);
            }
        }

        console.debug('cookie not present: ' + cname);
        return null;
    }*/
}
