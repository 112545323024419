import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { User } from './user/user';

import * as Global from '../global';
import { TranslateService } from '@ngx-translate/core';

import * as swal from 'sweetalert';
import { MenuService } from '../core/menu/menu.service';
import {
  clientesMenu,
  circuitosMenu,
//   filmesMenu,
  materiaisMenu,
  liberacaoMateriaisMenu,
  homeMenu,
  usersMenu,
  relatorioMenu,
  programacaoMenu
} from './menu';
import { Subject } from 'rxjs/Subject';
import { SettingsService } from '../core/settings/settings.service';

const activityKey = 'CP_USER_ACTIVITY_CODE';
const userKey = 'CP_USER_CODE';
const userSplitter = ';##@_@*;';

@Injectable()
export class RoutesService {


    public usuarioLogadoStatus: Subject<boolean> = new Subject();

    private user: User;

    constructor(public settingsService: SettingsService, public http: HttpClient, private cookies: CookieService, private routes: Router, private translate: TranslateService, private menuService: MenuService) {
    }

    public getAuthToken(): string {
      return this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
    }

    public getFileFromService(servicePath: string, successCallback, errorCallback?): void {
        const method = '[getFileFromService] ';

        console.log(method + 'starting get request to: ' + servicePath);

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        const headers = new HttpHeaders()
            .set('Authorization', authCookie);
        const options = {
        headers,
        responseType: 'blob' as 'blob'
        };

        this.http.get(Global.DATA_SERVER + servicePath, options)
            .subscribe((data) => {
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {
                console.log(method + 'error on get');
                if (errorCallback) {
                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }
            });
        
    }

    public downloadPdfFromService(servicePath: string) {
      const method = '[getFileFromService] ';
    
        console.log(method + 'starting get request to: ' + servicePath);

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        const headers = new HttpHeaders()
                .set('Authorization', authCookie);

        return this.http.get(Global.DATA_SERVER + servicePath, { headers, responseType: 'blob' }).map(
        (res) => {
            return new Blob([res], {type: 'application/pdf'});
        });
    }
  

    public getDataFromService(servicePath: string, successCallback, errorCallback?): void {
        const method = '[getDataFromService] ';

        console.log(method + 'starting get request to: ' + servicePath);

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
        const headers = new HttpHeaders()
            .set('Authorization', authCookie);

        this.http.get(Global.DATA_SERVER + servicePath, {headers})
            .subscribe((data) => {
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {
                console.log(method + 'error on get');
                if (errorCallback) {
                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }
            });
    }

    public postDataToService(servicePath: string, bodyData: object, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        let headers = new HttpHeaders()
            .set('Authorization', authCookie)
            .set('Content-Type', 'application/json');

        const method = '[postDataToService] ';

        if (!!additionalHeaders) {
            additionalHeaders.keys().forEach((key) => {
                console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                headers = headers.set(key, additionalHeaders.getAll(key));
            });
        }
        const options = {
            headers
        };
        console.log(method + 'starting post request to: ' + servicePath);

        this.http.post(Global.DATA_SERVER + servicePath, bodyData, options)
            .subscribe((data) => {
                console.log(method + ' response: ' + JSON.stringify(data));
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {

                console.log(method + 'error on post');
                if (errorCallback) {

                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }

            });


    }


    public postFileToService(servicePath: string, bodyData: object, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        let headers = new HttpHeaders()
            .set('Authorization', authCookie)
            .set('Content-Type', 'multipart/form-data');

        const method = '[postFileToService] ';

  
        if (!!additionalHeaders) {
            additionalHeaders.keys().forEach((key) => {
                console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                headers = headers.set(key, additionalHeaders.getAll(key));
            });
        }

        const options = {
        headers
        };

        console.log(method + 'starting post request to: ' + servicePath);

        this.http.post(Global.DATA_SERVER + servicePath, bodyData, options)
            .subscribe((data) => {
                if (successCallback) {

                console.log(method + 'success callback present, running');
                successCallback(data);
                }
            },
            (error) => {

                console.log(method + 'error on post');
                if (errorCallback) {

                console.log(method + 'calling error callback');
                errorCallback(error);
                }
            });

    }

    public putDataIntoService(servicePath: string, bodyData: object, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        let headers = new HttpHeaders()
            .set('Authorization', authCookie)
            .set('Content-Type', 'application/json');

        const method = '[putDataIntoService] ';
      
        if (!!additionalHeaders) {
            additionalHeaders.keys().forEach((key) => {
                console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                headers = headers.set(key, additionalHeaders.getAll(key));
            });
        }

        const options = {
            headers
        };

        console.log(method + 'starting post request to: ' + servicePath);
        console.log('PATH: ' + Global.DATA_SERVER + servicePath);
        this.http.put(Global.DATA_SERVER + servicePath, bodyData, options)
            .subscribe((data) => {
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {

                console.log(method + 'error on put');
                if (errorCallback) {

                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }
            });

    }

    public deleteDataFromService(servicePath: string, successCallback, errorCallback?, additionalHeaders?: HttpHeaders): void {

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        let headers = new HttpHeaders()
            .set('Authorization', authCookie)
            .set('Content-Type', 'application/json');

        const method = '[deleteDataFromService] ';

        if (!!additionalHeaders) {
            additionalHeaders.keys().forEach((key) => {
                console.log(method + 'adding provided header: ' + key + ', ' + additionalHeaders.getAll(key));

                headers = headers.set(key, additionalHeaders.getAll(key));
            });
        }


        const options = {
            headers
        };

        console.log(method + 'starting post request to: ' + servicePath);
        console.log('PATH: ' + Global.DATA_SERVER + servicePath);
        this.http.delete(Global.DATA_SERVER + servicePath, options)
            .subscribe((data) => {
                if (successCallback) {

                    console.log(method + 'success callback present, running');
                    successCallback(data);
                }
            },
            (error) => {

                console.log(method + 'error on delete');
                if (errorCallback) {

                    console.log(method + 'calling error callback');
                    errorCallback(error);
                }
            });

    }

    // *** Métodos Login ***
    public loginUser(user: string, pass: string, successCallback, errorCallback?): void {
        this.loginBackground(user, pass,
            () => {
                if (successCallback) {

                    successCallback();
                }
            },
            (err) => {

                if (errorCallback) {

                    errorCallback(err);
                }
            });
    }

    public enviarEmailRecuperarSenha(email: Object, successCallback, errorCallback?): void {

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
        const options = {
            headers,
        };

        this.http.post('/api/usuarios/recuperar-senha', email, options).subscribe(
            (data) => {
                if (successCallback) {
                    successCallback(data);
                }
            }, (err) => {
                if (errorCallback) {
                    errorCallback(err);
                }
            });
    }

    public getUsuario(idUsuario: number, successCallback, errorCallback?) {
        if (idUsuario > 0) {
            this.getDataFromService('usuarios/' + idUsuario,
                (data) => {
                    const entity: User = data;
                    if (successCallback) {
                        successCallback(entity);
                    }
                },
                (err) => {
                    if (errorCallback) {
                        errorCallback(err);
                    }
                });
        }
    }
    public updateSenhaUsuario(tokenRecuperacao: string, idUsuario: number, senha: string, idRecuperacao: number, successCallback, errorCallback?) {
        if (!!senha && !!tokenRecuperacao && !!idUsuario && !!idRecuperacao) {

            const userChanged = {
                token: tokenRecuperacao,
                idRecuperacao,
                idUsuario,
                senha
            };
            const headers = new HttpHeaders()
                .set('Content-Type', 'application/json');
            const options = {
                headers,
            };

            this.http.put('/api/usuarios/atualizar-senha/' + idRecuperacao, userChanged, options).subscribe(
                (data) => {
                    if (successCallback) {
                        successCallback(data);
                    }
                }, (error) => {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
        }
    }

    public validacaoUsuario(tokenValidacao: string, idUsuario: number, senha: string, successCallback, errorCallback?) {
        if (!!senha && !!tokenValidacao && !!idUsuario) {

            const userChanged = {
                senha
            };
            const headers = new HttpHeaders()
                .set('Content-Type', 'application/json');
            const options = {
                headers,
            };

            this.http.post('/api/usuarios/validar/' + idUsuario + '/' + tokenValidacao, userChanged, options).subscribe(
                (data) => {
                    if (successCallback) {
                        successCallback(data);
                    }
                }, (error) => {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
        }
    }

    public limparCookies() {
      this.cookies.setCookie(Global.COOKIE_LOGIN_NAME, '', 1);
      localStorage.removeItem(userKey);
      localStorage.removeItem(activityKey);
      localStorage.removeItem(Global.USER_KEY);
    }

    public logOut() {
        this.limparCookies();
        this.user = null;
        this.routes.navigateByUrl('/login');
  
    }

    public getLoggedUsername(): string {
        return atob(localStorage.getItem(Global.USER_KEY)).toLowerCase();
    }

    private loginBackground(user: string, pass: string, success, failure?) {

        const dados = {
            username: user,
            password: pass
        };

        const method = '[loginUser] ';

        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Credentials', 'true');
   
        this.http.post<Object>(Global.DATA_SERVER + 'usuarios/login', dados, {headers, observe: 'response'})
            .subscribe((data : HttpResponse<any>) => {
                
                console.log(JSON.stringify(data))
                const headerValues: HttpHeaders = data.headers;
                const devMode = false;

                // Para rodar aplicações CORS, é necesário que o ip de origem e ip de destinos sejam iguais, inclusive porta.
                // Caso contrário nao é possivel ler o header de Authorization.
                // Para configurar, veja os detalhes para redirecionamento de portas no Apache ou Wamp ou equivalentes...
                // Atualmente também existe configuração para rodar no profile scaletunnel ou dev
                if (headerValues.has('Authorization')) {
                    console.log(headerValues.get('Authorization'));
                    this.cookies.setCookie(Global.COOKIE_LOGIN_NAME, headerValues.get('Authorization'), 60);

                    localStorage.setItem(Global.USER_KEY, btoa(user));
                    const userValue = user + userSplitter + pass;

                    localStorage.setItem(userKey, btoa(userValue));
                    console.log(method + 'login complete with cookie set');

                    this.registerActivity();

                    this.setUsuarioLogado(() => {
                          if (success) success();
                      }, (err) => {
                          this.limparCookies();
                          if (failure) failure(err);
                      });

                } else {
                  if (failure) failure();
                }
            },
            (err) => {
                this.user = undefined;
                this.usuarioLogadoStatus.next(false);
                console.log(method + 'Error: ' + err);
                  if (failure) {
                      failure(err);
                  }
            });
    }


    private setUsuarioLogado(success, failure) {
        const method = '[setUsuarioLogado] ';
        const servicePath = '/usuarios/meu-usuario';
        console.log(method + 'starting get request to: ' + servicePath);

        const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);

        const headers = new HttpHeaders()
            .set('Authorization', authCookie);

        const options = {
            headers,
        };

        this.http.get(Global.DATA_SERVER + servicePath,  options)
          .subscribe((data: User) => {
              console.log(method + 'success callback present, running');

              this.user = data;
              this.setupMenu();
              this.setupSettings();
              
              this.usuarioLogadoStatus.next(true);

              if (success) success();
            },
            (err) => {
              console.log(method + 'error on get');

              this.tratarErro(err);

              this.usuarioLogadoStatus.next(false);
              if (failure) failure();
            });
    }

    private predictSessionInvalid(): boolean {
      let token_expiration = 36000000; // Em ms 10h
      token_expiration = token_expiration / 1.5; // Parcela do tempo para garantir que nao de problema
      return (this.lastActivityOffset() > (token_expiration));
    }

    private relogarComDadosDoCookie() {

      const userData = localStorage.getItem(userKey);

      console.log('requesting new login token');
      const values = atob(userData).split(userSplitter);
      const username = values[0];
      const userpass = values[1];

      // ao completar com sucesso o cookie é armazenado, caso contrário o login existente não é mais válido
      this.loginBackground(username, userpass,
        () => {
          console.log('new token OK');
          this.usuarioLogadoStatus.next(true);
        }, () => {
          this.routes.navigateByUrl('/login');
          this.clearActivity();
          this.usuarioLogadoStatus.next(false);
        });
    }

  private validateLogin(): Observable<boolean> {
    const method = '[checkLogin] ';
    console.log(method + 'called');

    const authCookie = this.cookies.getCookie(Global.COOKIE_LOGIN_NAME);
   
    if (!!authCookie && !this.predictSessionInvalid()) {
      // Existe o token (Não quer dizer muita coisa) // Verifica se a sessão está parada a x tempo desde o login
      this.registerActivity();
      this.usuarioLogadoStatus.next(true);
      return this.usuarioLogadoStatus;
    } else {
      console.log(method + 'authCookie not available');
      const userData = localStorage.getItem(userKey);

      if (!!userData) {
        this.relogarComDadosDoCookie();
      } else {
        console.log(method + 'userData not available');
        this.routes.navigateByUrl('/login');
        this.usuarioLogadoStatus.next(false);
      }

    }

    return this.usuarioLogadoStatus;
  }

    public temPermissao(modulo: string , tipo: string) {
      return (this.user && this.user.permissoes  &&
        this.user.permissoes.filter(perm => perm.modulo === modulo && perm.tipo === tipo).length > 0);
    }

    // : Observable<boolean>
    public isUsuarioLogado() {
        // TODO este metodo é usado na tela de login e, por ser async, nao tem a informação na hora necessária. Implementar como observable

        return !!this.user;
        // return this.userObservable.subscribe((valor) =>
        //     success(this.user != undefined)
        // );
    }

    private getUsuarioLogado() {
      return this.user;
    }

    public tratarErro(err): string {
        const method = '[tratarErro] ';
        console.log(method + 'err: ' + JSON.stringify(err));

      if (err.status === 0) {
        swal(
          this.translate.instant('general.error.TITLE'),
          this.translate.instant('general.error.ZERO'),
          'error');

        ///this.routes.navigateByUrl('/home');

        return this.translate.instant('general.error.ZERO');
        // return this.translate.instant('general.error.ZERO');
      } else if (err.status === 401 || err.status === 403) {
        swal(
          this.translate.instant('general.error.FORBIDDEN_TITLE'),
          this.translate.instant('general.error.FORBIDDEN'),
          'error');

        ///this.routes.navigateByUrl('/home');

        return this.translate.instant('general.error.FORBIDDEN');
        // return this.translate.instant('general.error.FORBIDDEN')
      }
      return null;
    }

    private clearActivity() {
        localStorage.setItem(activityKey, btoa('' + new Date(0).getTime()));
    }


    private registerActivity() {
        localStorage.setItem(activityKey, btoa('' + new Date().getTime()));
    }

    private lastActivityOffset(): number {

        let v = localStorage.getItem(activityKey);
        if (!!v) {
            v = atob(v);
        } else {
            v = '0';
        }

        return new Date().getTime() - parseInt(v);
    }

    private setupMenu() {
        const menuItems = [];
        // Cria os menus de acordo com as permissoes
        menuItems.push(homeMenu);

        if (this.temPermissao('CLIENTES', 'READ')) {
            menuItems.push(clientesMenu);
        }

        if (this.temPermissao('CLIENTES', 'READ')) {
            menuItems.push(circuitosMenu);
        }

        if (this.temPermissao('MATERIAIS', 'READ')) {
            menuItems.push(materiaisMenu);
        }

        if (this.temPermissao('MATERIAIS', 'READ')) {
            menuItems.push(liberacaoMateriaisMenu);
        }

        if (this.temPermissao('PROGRAMACAO_TRAILERS', 'READ')) {
            menuItems.push(programacaoMenu);
        }

        if (this.temPermissao('RELATORIOS', 'READ')) {
            menuItems.push(relatorioMenu);
        }

        // if (this.temPermissao('USUARIOS', 'READ')) {
            menuItems.push(usersMenu);
        // }
        this.menuService.addMenu(menuItems);

    }

    init() {
        // this.validateLogin().subscribe((valor) => {
        //     if (valor) {
        //         this.setUsuarioLogado(null, null);
        //     } else {
        //         console.log('Não logado.');
        //         this.routes.navigateByUrl('/login');
        //     }
        // });
    }

    private setupSettings() {
      this.settingsService.setUserSetting('name', this.user.nome);
      this.settingsService.setUserSetting('job', this.user.funcao);
    }
}
