import {
    Component, Input, ViewChild, OnChanges
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

declare var jQuery: any;
declare var $: any;

@Component({
    selector: 'wait-modal',
    templateUrl: './waitmodal.component.html',
    styleUrls: ['./waitmodal.component.scss'],
})
export class WaitModal implements OnChanges{
    @Input() showModal = false;
    @ViewChild(ModalDirective) waitModal : ModalDirective;

  ngOnChanges(){
      if (this.showModal)
        this.showChildModal();
      else
        this.hideChildModal();

  }
  public showChildModal(): void {
    if(!!this.waitModal)
    this.waitModal.show();
  }

  public hideChildModal(): void {
    if(!!this.waitModal)
    this.waitModal.hide();
  }
}
