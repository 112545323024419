import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomValidators } from 'ng2-validation';
import { SettingsService } from '../../../core/settings/settings.service';

import { RoutesService } from '../../routes.service';
import { User } from '../../user/user';

@Component({
    moduleId: module.id,
    selector: 'app-nova-senha',
    templateUrl: './nova-senha.component.html',
    styleUrls: ['./nova-senha.component.scss']
})

export class NovaSenhaComponent implements OnInit {

    valForm: FormGroup;
    tokenRecuperacao: string;
    tokenValidacao: string;
    idUsuario: number;
    idRecuperacao: number;

    public user: User = new User();

    public showError = {
        actived: false,
        message: ''
    };

    public showSucess = {
        actived: false,
        message: ''
    };

    constructor(public settings: SettingsService, fb: FormBuilder, private router: Router, private service: RoutesService, private routeActived: ActivatedRoute) {
        this.valForm = fb.group({
            'senha': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
            'senhaConfirmacao': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
        });
    }

    ngOnInit() {
        this._carregaParametros();
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            if (!!this.tokenRecuperacao && !!this.idUsuario && !!this.idRecuperacao) {
                this.service.updateSenhaUsuario(this.tokenRecuperacao, this.idUsuario, this.valForm.controls['senha'].value, this.idRecuperacao,
                    (data) => {
                        this.showSucess = {
                            actived: true,
                            message: 'Senha alterada com sucesso!'
                        };
                        setTimeout(() => {
                            this._reloadPage();
                        }, 1500);  
                    }, (error) => {
                        if (error.status == '400') {
                            this.showError = {
                                actived: true,
                                message: 'Ocorreu um erro ao atualizar sua senha, entre em contato com o suporte.'
                            };
                        } else if (error.status == '406') {
                            this.showError = {
                                actived: true,
                                message: 'O tempo da sua solicitação de nova senha expirou, por favor peça uma nova solicitação de senha.'
                            };
                        } else {
                            this.showError = {
                                actived: true,
                                message: 'Ocorreu um erro ao atualizar sua senha, por favor tente novamente.'
                            };
                        }
                        console.debug(error);
                    });
                    
            
            } else if(!!this.tokenValidacao && !!this.idUsuario ){
                this.service.validacaoUsuario(this.tokenValidacao, this.idUsuario, this.valForm.controls['senha'].value,
                    (data) => {
                        this.showSucess = {
                            actived: true,
                            message: 'Usuário validado com sucesso!'
                        };
                        setTimeout(() => {
                            this._reloadPage();
                        }, 1500);    
                    }, (error) => {
                        if (error.status == '404') {
                            this.showError = {
                                actived: true,
                                message: 'Usuário não encontrado.'
                            };
                        }
                        else if (error.status == '406') {
                            this.showError = {
                                actived: true,
                                message: 'Url invalida.'
                            };
                        } else {
                            this.showError = {
                                actived: true,
                                message: 'Ocorreu um erro ao atualizar sua senha, por favor tente novamente.'
                            };
                        }
                        console.debug(error);
                    });

            }
            
        } 


    }

    private _reloadPage(): void {
        
        this.router.navigateByUrl('/login');
    
    }

    private _carregaParametros(): void {
        //Carrrega o usuario da base atraves do ID enviado no parametro
        this.routeActived.params.subscribe(params => {
            this.tokenRecuperacao = params['tokenRecuperacao'];
            this.idUsuario = params['idUsuario'];
            this.idRecuperacao = params['idRecuperacao'];
            this.tokenValidacao = params['tokenValidacao'];
        });

    }
    
}