import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2DatepickerComponent } from './ng2-datepicker/ng2-datepicker.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [Ng2DatepickerComponent],
  exports: [Ng2DatepickerComponent]
})
export class DatePickerModule { }
