'use strict';

import {isDevMode} from "@angular/core";

export const INICIO_DOS_TEMPOS = '2000-01-01';

//export const DATA_SERVER = (isDevMode() ? 'http://universal.scale.com.br/api/' : '/api/');

// export const DATA_SERVER =
//   (location.origin == 'http://admin.universalexibidor.com' || location.origin == 'https://admin.universalexibidor.com' ? '/api/' :
//   (location.origin == 'http://universal.scale.com.br' || location.origin == 'https://universal.scale.com.br'? '/api/'
//     : '/api/'));
    //: 'http://localhost:8091/api/'));

export const DATA_SERVER = '/api/';

const admin_patt = /admin./g;

export const SITE_HOSTNAME = //(isDevMode() ? 'http://universal' : 'https://universalexibidor.com');
  admin_patt.test(location.host) ? location.origin.replace('admin.','') : 'http://universal.test';


export const COOKIE_LOGIN_NAME = 'autorizacaoUsoBackoffice';
export const COOKIE_DOMAIN_PATH = '';
export const VERSION = '0.1';
export const USER_KEY = 'CP_BACKOFFICE_USER_CODE';
export const SHA256KEY = 'materiais-app-key-#!$%199';














// "use strict";

// import {isDevMode} from "@angular/core";

// export const INICIO_DOS_TEMPOS = "2000-01-01";

// export const DATA_SERVER = ("/api/");

// // export const DATA_SERVER =
// //   (location.origin == 'http://admin.universalexibidor.com' || location.origin == 'https://admin.universalexibidor.com' ? '/api/' :
// //   (location.origin == 'http://universal.scale.com.br' || location.origin == 'https://universal.scale.com.br'? '/api/'
// //     : '/api/'));
//     //: 'http://localhost:8091/api/'));

// //export const DATA_SERVER = '/api/';
// export const SITE_HOSTNAME = (location.origin);
// // export const SITE_HOSTNAME = //(isDevMode() ? 'http://universal' : 'https://universalexibidor.com');
// //   (location.origin == "http://admin.universalexibidor.com" || location.origin == "https://admin.universalexibidor.com" ? "https://universalexibidor.com" :
// //   (location.origin == "http://universal.scale.com.br" || location.origin == "https://universal.scale.com.br"? "https://universalexibidor.scale.com.br"
// //    : "http://universal"));


// export const COOKIE_LOGIN_NAME = "autorizacaoUsoBackoffice";
// export const COOKIE_DOMAIN_PATH = "";
// export const VERSION = "0.1";
// export const USER_KEY = "CP_BACKOFFICE_USER_CODE";
// export const SHA256KEY = "materiais-app-key-#!$%199";
