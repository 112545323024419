import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslatorService} from '../core/translator/translator.service';
import {MenuService} from '../core/menu/menu.service';
import {SharedModule} from '../shared/shared.module';
import {LoginModule} from './login/login.module';
import {RecuperarSenhaModule} from './recuperar-senha/recuperar-senha.module';
import {NovaSenhaModule} from './recuperar-senha/nova-senha/nova-senha.module';

import {routes} from './routes';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes),
    LoginModule,
    RecuperarSenhaModule,
    NovaSenhaModule
  ],
  exports: [
    RouterModule
  ]
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
    //this.menuService.setup();
  }
}
