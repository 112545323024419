import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FiltroMultiplex } from 'app/modelo/filtromultiplex';
import { RoutesService } from 'app/routes/routes.service';

declare var $: any;

@Component({
  selector: 'filtros-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  filtro: FiltroMultiplex;

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId = 'dashboard';

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public chamarInicializacao = false;

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name receptorFiltro
     */
  @Input() public receptorFiltro: (filtro: FiltroMultiplex) => void;

  constructor(private server: RoutesService, private router: Router) {

    if (this.filtro == null) {
      this.filtro = new FiltroMultiplex();
      this.filtro.dataInicio = new Date((new Date().getTime()) - (1000 * 60 * 60 * 24 * 7)).toISOString().substr(0, 10);
      this.filtro.dataTermino = new Date().toISOString().substr(0, 10);
      this.filtro.empresa = '';
    }
  }

  ngOnInit() {
    if(!this.server.isUsuarioLogado()){
      this.router.navigateByUrl('/login');
    } else{
      if (this.chamarInicializacao === true && this.receptorFiltro && this.filtro)
        this.receptorFiltro(this.filtro);
    }

  }

  aplicar() {
    if (this.receptorFiltro) {
      this.receptorFiltro(this.filtro);
    }

  }
    
}
