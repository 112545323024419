import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { DatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { RatingModule } from "ngx-bootstrap/rating";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NgxPaginationModule } from 'ngx-pagination';

import { ColorsService } from "./colors/colors.service";
import { CheckallDirective } from "./directives/checkall/checkall.directive";
import { EasypiechartDirective } from "./directives/easypiechart/easypiechart.directive";
import { FlotDirective } from "./directives/flot/flot.directive";
import { JqcloudDirective } from "./directives/jqcloud/jqcloud.directive";
import { NowDirective } from "./directives/now/now.directive";
import { ScrollableDirective } from "./directives/scrollable/scrollable.directive";
import { SparklineDirective } from "./directives/sparkline/sparkline.directive";
import { VectormapDirective } from "./directives/vectormap/vectormap.directive";

// import { AmChartsModule } from "@amcharts/amcharts3-angular";

import { DatePickerModule } from "../components/datepicker/datepicker.module";
import { LoadersModule } from "../components/loaders/loaders.module";
import { CustomModalModule } from "../components/modal/modal.module";

import { NgSelectModule } from "@ng-select/ng-select";
import { FiltroModule } from "../filtro/filtro.module";
import { ToastrModule } from "ngx-toastr";

// https://angular.io/styleguide#!#04-10
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    AccordionModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    DatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    ProgressbarModule.forRoot(),
    RatingModule.forRoot(),
    TabsModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    ToastrModule,
    // AmChartsModule,
    DatePickerModule,
    FiltroModule,
    CustomModalModule,

    LoadersModule,
    NgxPaginationModule,
  ],
  providers: [
    ColorsService,
  ],
  declarations: [
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    AlertModule,
    ButtonsModule,
    CarouselModule,
    CollapseModule,
    DatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    ProgressbarModule,
    RatingModule,
    TabsModule,
    TimepickerModule,
    TooltipModule,
    TypeaheadModule,
    FlotDirective,
    SparklineDirective,
    EasypiechartDirective,
    CheckallDirective,
    VectormapDirective,
    NowDirective,
    ScrollableDirective,
    JqcloudDirective,
    // AmChartsModule,
    DatePickerModule,
    FiltroModule,
    CustomModalModule,
    LoadersModule,
    NgSelectModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
