import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    user: any;
    constructor(public userblockService: UserblockService, private settings: SettingsService) {

        this.user = {
            picture: settings.getUserSetting('picture'),
            name: settings.getUserSetting('name'),
            job: settings.getUserSetting('job')
        };
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}
