import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import {LoadersModule} from '../loaders/loaders.module';
import { WaitModal } from './waitmodal/waitmodal.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ModalModule, LoadersModule],
  declarations: [WaitModal],
  exports: [WaitModal]
})
export class CustomModalModule { }
