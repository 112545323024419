import {Permissao} from "./permissao";

export class User {
    id: number;
    nome: string;
    sobrenome: string;
    email: string;
    funcao: string;
    departamento: string;
    telefone: string;
    contatarPara: string;
    ativo: boolean;
    exibirContatoSite: boolean;
    permissoes: Permissao[];
}
