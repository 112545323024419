import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../../shared/shared.module';
import { NovaSenhaComponent } from './nova-senha.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        NovaSenhaComponent
    ],
    exports: [
        RouterModule,
        NovaSenhaComponent
    ]
})
export class NovaSenhaModule { }
