import { Component, OnInit, OnDestroy, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

declare var $: any;

const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

import { UserblockService } from "../sidebar/userblock/userblock.service";
import { SettingsService } from "../../core/settings/settings.service";
import { MenuService } from "../../core/menu/menu.service";
import { RoutesService } from "../../routes/routes.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    elem: any;
    fullscreen = false;

    isNavSearchVisible: boolean;
    @ViewChild("fsbutton") fsbutton;  // the fullscreen button

    constructor(public menu: MenuService, public userblockService: UserblockService, public settings: SettingsService, private server: RoutesService, @Inject(DOCUMENT) private document: any) {

        this.menuItems = menu.getMenu(); // for horizontal layout

    }

    ngOnInit() {
        this.elem = document.documentElement;
        this.isNavSearchVisible = false;
        if (isIEOrEdge) { // Not supported under IE
            this.fsbutton.nativeElement.style.display = "none";
        }
    }

    ngOnDestroy() {
        this.closeFullscreen();
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.debug(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.layout.offsidebarOpen = !this.settings.layout.offsidebarOpen;
    }

    toggleCollapsedSideabar() {
        this.settings.layout.isCollapsed = !this.settings.layout.isCollapsed;
    }

    isCollapsedText() {
        return this.settings.layout.isCollapsedText;
    }

    toggleFullScreen(event) {

        if (this.fullscreen) {
            this.closeFullscreen();
            this.fullscreen = false;
        } else {
            this.openFullscreen();
            this.fullscreen = true;
        }

        // Switch icon indicator
        const el = $(this.fsbutton.nativeElement);
        if (this.fullscreen) {
            el.children("em").removeClass("fa-expand").addClass("fa-compress");
        }
        else {
            el.children("em").removeClass("fa-compress").addClass("fa-expand");
        }
    }

    logOut() {
        this.server.logOut();
    }

    openFullscreen() {
        if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
          /* Firefox */
          this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
          /* IE/Edge */
          this.elem.msRequestFullscreen();
        }
    }

    /* Close fullscreen */
    closeFullscreen() {
        if (this.document.exitFullscreen) {
          this.document.exitFullscreen();
        } else if (this.document.mozCancelFullScreen) {
          /* Firefox */
          this.document.mozCancelFullScreen();
        } else if (this.document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          this.document.webkitExitFullscreen();
        } else if (this.document.msExitFullscreen) {
          /* IE/Edge */
          this.document.msExitFullscreen();
        }
    }
}