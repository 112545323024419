import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { RecuperarSenhaComponent } from './recuperar-senha.component';

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        RecuperarSenhaComponent
    ],
    exports: [
        RouterModule,
        RecuperarSenhaComponent
    ]
})
export class RecuperarSenhaModule { }
