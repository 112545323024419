import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { CustomValidators } from "ng2-validation";
import { SettingsService } from "../../core/settings/settings.service";

import { RoutesService } from "../routes.service";
import * as swal from "sweetalert";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

    valForm: FormGroup;

    asyncOp = false;

    public errorMessage: string;

    constructor(public settings: SettingsService,  private translate: TranslateService, fb: FormBuilder, private route: Router, private service: RoutesService) {

        this.valForm = fb.group({
            email: [null, Validators.compose([Validators.required, CustomValidators.email])],
            password: [null, Validators.required]
        });

    }

    ngOnInit() {
      this.service.usuarioLogadoStatus.subscribe(() => {
        if (this.service.isUsuarioLogado()) {
          this.route.navigateByUrl("/home");
        } else{
          this.route.navigateByUrl("/login");
        }
      });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        this.asyncOp = true;

        for (const c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        if (this.valForm.valid) {
            console.debug("Form valid");
            this.service.loginUser(this.valForm.controls.email.value, this.valForm.controls.password.value,
                () => {
                    // LOGIN OK
                    console.debug("return is ok");
                    setTimeout(() => {
                        this.asyncOp = false;
                        this.route.navigateByUrl("/");
                    }, 300);
                }, (err) => {
                    // toast?
                    console.debug(err);
                    this.asyncOp = false;
                    if (err.status === 401) {
                      this.errorMessage = "Usuário ou senha inválidos";
                    } else {
                      if (err.status === 0) {
                        swal(
                          this.translate.instant("general.error.TITLE"),
                          this.translate.instant("general.error.ZERO"),
                          "error");
                        this.errorMessage = this.service.tratarErro(err);
                      }
                    }
                });
        }
    }
}
