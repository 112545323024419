import "rxjs/add/operator/map";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from "@angular/core";
import { HttpClientModule, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from "@angular/common/http";
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

import { AppComponent } from "./app.component";

import { CoreModule } from "./core/core.module";
import { LayoutModule } from "./layout/layout.module";
import { SharedModule } from "./shared/shared.module";
import { RoutesModule } from "./routes/routes.module";

import { RoutesService } from "./routes/routes.service";
import { CookieService } from "./routes/cookie.service";
import { CookieService as NgCookie } from "ngx-cookie-service";

import { AlertModule } from "ngx-bootstrap/alert";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import localePT from "@angular/common/locales/pt";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
registerLocaleData(localePT);
import {AutoCompleteModule} from "primeng/primeng";
import { ToastrModule } from "ngx-toastr";
// import { BrowserModule } from '@angular/platform-browser';

// HttpClients://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(HttpClient: HttpClient) {
    return new TranslateHttpLoader(HttpClient, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [

        NgbModule, //att 2021/7/19
        FontAwesomeModule,

        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        AutoCompleteModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        NgxDatatableModule,
        AlertModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot({
            timeOut: 5000,
          }),
        // BrowserModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "pt-BR" },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        CookieService,
        NgCookie,
        RoutesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
