import { Injectable } from "@angular/core";


@Injectable()
export class MenuService {

    menuItems: any[];

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: {
        text: string,
        heading?: boolean,
        link?: string,
        icon?: string,
        alert?: string,
        submenu?: any[]
    }[]) {
        this.menuItems = [];
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {
        return this.menuItems;
    }
}
