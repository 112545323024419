
export class FiltroMultiplex {
    periodo: string;
    dataInicio: string;
    dataTermino: string;
    empresa: string;
    cidades: string;
    cinemas: string;
    produtos: string;
}
