import { Component, OnInit, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { RoutesService } from '../../routes/routes.service';
import { FiltroMultiplex } from '../../modelo/filtromultiplex';
import { Router } from '@angular/router';

@Component({
  selector: 'filtro-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss']
})
export class ProdutoComponent implements OnInit, ControlValueAccessor {

  tagProdutosValue;
  tagProdutosOptions;

  /**
     * @description id atribuido a div pai
     * @name objectId
     * @type {string}
     */
  @Input() public objectId = 'localidade-filtro';

  /**
     * @description função que será chamada ao clique do botão Aplicar
     * @name filtroModel
     * @type {FiltroMultiplex}
     */
  @Input() public filtro: FiltroMultiplex;

  changeCallback: (item: FiltroMultiplex) => void;
  touchCallback: () => void;

  constructor(private server: RoutesService, private router: Router) { }

  ngOnInit() {
    if(!this.server.isUsuarioLogado()){
      this.router.navigateByUrl('/login');
    } else{
      this.fillProdutos();
    }
    
  }

  private limparProdutos() {
    this.tagProdutosValue = [];
  }

  public get filtroObj(): FiltroMultiplex {
    return this.filtro;
  }

  public writeValue(items: FiltroMultiplex) {
    this.filtro = items || new FiltroMultiplex();
  }

  public registerOnChange(fn: any) {
    this.changeCallback = fn;
  }

  public registerOnTouched(fn: any) {
    this.touchCallback = fn;
  }

  filtrarProdutos(ev) {
    this.aplicarTags();
  }

  private fillProdutos() {

    let query = '';

    if (this.filtro.empresa) {
      query += 'empresa=' + this.filtro.empresa + '&';
    }

    query += this.queryTimestamp();

    this.server.getDataFromService('produtorepository/autocompletar/produtos?' + query,
      (data) => {
        this.limparProdutos();
        this.tagProdutosOptions = data;
      });
  }

  private queryTimestamp(): string {
    return 'cache=false&timestamp=' + new Date().getTime();
  }

  private tagToValue(tag) {
    return tag.value != null ? tag.value + ',' : '';
  }

  private aplicarTags() {
    if (this.tagProdutosValue != null) {

      let cid = '';
      this.tagProdutosValue.forEach(element => {
        cid += this.tagToValue(element);
      });

      if (cid.length > 0) {
        this.filtro.produtos = cid.substr(0, cid.length - 1);
      } else {
        this.filtro.produtos = null;
      }
    } else {
      this.filtro.produtos = null;
    }
  }
}
