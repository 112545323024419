
export const homeMenu = {
    text: "Home",
    translate: "general.HOME",
    link: "/home",
    icon: "fa fa-home"
};

export const headingMainMenu = {
    text: "Main Navigation",
    translate: "dashboard.WELCOME",
    heading: true
};

export const usersMenu = {
    text: "Usuários",
    translate: "users.list.TITLE",
    link: "/user/list",
    icon: "fa fa-users"
};

export const clientesMenu = {
    text: "Clientes",
    translate: "clientes.lista.TITLE",
    link: "/clientes/lista",
    icon: "fa fa-child"
};

export const circuitosMenu = {
    text: "Circuitos",
    translate: "circuitos.lista.TITLE",
    link: "/circuitos/lista",
    icon: "fa fa-map-marker"
};

export const relatorioMenu = {
  text: "Relatórios",
  translate: "relatorioDownloads.lista.TITLE",
  link: "/relatorioDownloads/lista",
  icon: "fas fa-file-alt",
};


export const relatoriosMenu = {
    text: "Relatórios",
    translate: "relatorios.TITLE",
    link: "/relatorios/lista",
    icon: "fas fa-file-alt",
    submenu: [
        {
            text: "Compras e Frequência",
            translate: "relatorios.comprasfrequencia.TITLE",
            link: "/relatorios/compras-frequencia"
        },
        {
            text: "Evolução de Cadastro",
            translate: "relatorios.evolucaocadastro.TITLE",
            link: "/relatorios/evolucao-cadastro"
        },
        {
            text: "Participação",
            translate: "relatorios.participacao.TITLE",
            link: "/relatorios/participacao"
        },
        {
            text: "Prêmios",
            translate: "relatorios.premios.TITLE",
            link: "/relatorios/premios"
        },
        {
            text: "Produtos",
            translate: "relatorios.produtos.TITLE",
            link: "/relatorios/produtos"
        },
        {
            text: "Canais de Pontuação",
            translate: "relatorios.canaispontuacao.TITLE",
            link: "/relatorios/canais-pontuacao"
        }
    ]
};

export const categoriasMenu = {
  text: "Categorias",
  translate: "categorias.lista.TITLE",
  link: "/categorias/lista",
  icon: "fa fa-folder-o"
};

// export const filmesMenu = {
//   text: "Filmes",
//   translate: "filmes.lista.TITLE",
//   link: "/filmes/lista",
//   icon: "fa fa-film"
// };

export const programacaoMenu = {
  text: "Programação de Trailers",
  translate: "programacao.lista.TITLE",
  link: "/programacao/lista",
  icon: "fa fa-film"
};

export const materiaisMenu = {
  text: "Materiais",
  translate: "materiais.lista.TITLE",
  link: "/materiais/lista",
  icon: "fa fa-folder",
};

export const liberacaoMateriaisMenu = {
    text: "Liberação",
    translate: "liberacaoMateriais.lista.TITLE",
    link: "/liberacaoMateriais/lista",
    icon: "fas fa-unlock-alt",
  };
  


// Ordem é igual a descrita abaixo
// export const menu = [
//   Home,
//   clientesMenu,
//   circuitosMenu,
//   materiais
//      Liberacao Materiais
//   programação
//   relatorios
//   usersMenu
// ];
